import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { RoutesEnum } from "../../enums/enums";
import { useUsersControllerDeleteUserMutation } from "../../rtk/codegen/core-api";
import DButton from "../d-button/d-button";

const modalId = "modalConfirmDeletion";

export const LogoutButton: React.FC = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
        // returnTo: RoutesEnum.login,
      },
    });
  };

  const [usersControllerDelete, { isLoading: isLoadingRemove }] =
    useUsersControllerDeleteUserMutation();

  const handleDelete = async () => {
    await usersControllerDelete();
    handleLogout();
  };

  const getModal = (id: string) =>
    document.getElementById(id) as HTMLDialogElement;

  return (
    <>
      <DButton
        className={"btn btn-sm btn-primary"}
        disabled={isLoadingRemove}
        isLoading={isLoadingRemove}
        name={"Delete account"}
        onClick={() => {
          getModal(modalId).showModal();
        }}
      />
      <a className="btn btn-sm btn-error" onClick={handleLogout}>
        Log Out
      </a>
      <dialog id={modalId} className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Confirm deletion</h3>
          <p className="py-4">
            Are you sure you want to delete your account? This action is
            irreversible, and all your data will be permanently lost.
          </p>
          <div className="modal-action">
            <form method="dialog" className="flex gap-2">
              <button className="btn">Cancel</button>
              <DButton
                className={"btn btn-error"}
                disabled={isLoadingRemove}
                isLoading={isLoadingRemove}
                name={"Delete"}
                onClick={async (e: any) => {
                  e.preventDefault();
                  await handleDelete();
                  getModal(modalId).close();
                }}
              />
            </form>
          </div>
        </div>
      </dialog>
    </>
  );
};
